import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Header from "../Header";

import Footer from "../Footer";

const Article = styled.article`
  width: 100%;

  ul,
  li,
  blockquote,
  figcaption {
    font-size: 21px !important;
    font-weight: 300;
  }
  @media (max-width: 750px) {
    ul,
    li,
    blockquote,
    figcaption {
      font-size: 18px !important;
      font-weight: 300;
    }
  }
`;
const ArticleContent = styled.div`
  padding-top:${props => props.ignoreTopPadding ? "0rem" : "6rem"};
  padding-bottom: 15rem;

  @media (max-width: 750px) {
    padding-top:${props => props.ignoreTopPadding ? "0rem" : "1rem"};
    padding-bottom: 4rem;
  }
`;

const Main = styled.main`
  position: relative;
  width: 100%;

  @media (max-width: 750px) {
    margin-top: 4rem;
  }
`;


const BlogLayout = props => {
  const [windowWidth, setWindowWidth] = useState(false)
  const [hideHeader, shouldHideHeader] = useState(false)
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    };

    handleResize()

    window.addEventListener('resize', handleResize)


    const checkSearchParams = () => {
       const params = new URLSearchParams(window.location.search);
       const headless = params.get("headless");
       if (headless && headless == "true") shouldHideHeader(true)
    }

    checkSearchParams()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const { ignoreTopPadding, children } = props

  return (
    <Main>
      {!hideHeader && <Header routeToHomepage enforceBackground={props.enforceBackground} />}

      <Article>
        <ArticleContent ignoreTopPadding={ignoreTopPadding}>{children}</ArticleContent>
      </Article>

      <Footer id="mf" windowWidth={windowWidth} bottom="unset" />
    </Main>
  )
}

export default BlogLayout