import React from "react";
import styled from "styled-components";
import { P } from "../Typography";
import { withPrefix } from "gatsby";

const Image = ({ src, title, openOverlay, prefix, externalSrc, className }) => {
  const openImage = () => {
    const fullRes = `/images/media/${prefix}/${src}`;

    const img = {
      src: fullRes,
      externalSrc,
      title
    };

    openOverlay(img);
  };

  const onKeyPress = e => {
    if (e.keycode === 13) {
      openImage()
    }
  }

  const renderedSrc =
    externalSrc || withPrefix(`/images/media/${prefix}/${src}`);

  return (
    <ImageEl className={className}>
      <figure>
        <ImageHeader>
          {!!title && <P>{title}</P>}

          <LinksHeader>
            <span
              onClick={openImage}
              role="button"
              tabIndex="0"
              aria-label="Expand"
              onKeyDown={onKeyPress}
            >
              <img
                src={`${withPrefix("/images/media/expand.svg")}`}
                alt="Expand"
              />
            </span>

            <a href={renderedSrc} download>
              <img
                src={`${withPrefix("/images/media/download.svg")}`}
                alt="Expand"
              />
            </a>
          </LinksHeader>
        </ImageHeader>

        <img src={renderedSrc} alt={title} />
      </figure>
    </ImageEl>
  );
};

const ImageEl = styled.section`
  width: calc(50% - 1rem);
  overflow: hidden;
  position: relative;

  figure {
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
  }

  &:hover header {
    opacity: 1;
  }

  @media (max-width: 750px) {
    width: 100%;
  }
`;

const ImageHeader = styled.header`
  opacity: 0;
  transition: 0.4s ease-out opacity;
  position: absolute;
  top: -3rem;
  left: 0;
  width: 100%;
  padding: 1rem;
  padding-bottom: 5rem;
  background: linear-gradient(180deg, #151029 37.21%, rgba(21, 16, 41, 0) 100%);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  p {
    margin-top: 3rem;
  }
`;

const LinksHeader = styled.div`
  margin-top: 3rem;
  img {
    width: 100%;
  }

  span,
  a {
    display: inline-block;
    width: 0.9rem;
    margin-left: 1rem;
    vertical-align: middle;
    cursor: pointer;
  }
  svg {
    width: 100%;
  }

  @media (max-width: 750px) {
    span,
    a {
      width: 1.25rem;
    }
  }
`;

export default Image;
