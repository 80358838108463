import React, { useState } from "react";
import styled from "styled-components";

import FeaturedHeader from "../Blog/FeaturedHeader";
import Image from "./Image";
import ExpandedImage from "./ExpandedImage";

const Gallery = styled.section`
  margin-bottom: 4rem;
`;

export const GalleryList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start
  ;
  justify-content: space-between;
  padding: 2rem;
  max-width: 100rem;
  min-height:100vh;
  margin-left: auto;
  margin-right: auto;
`;

const MediaGallery = ({
  title, content, prefix
}) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Gallery>
      <FeaturedHeader>{title}</FeaturedHeader>
      <GalleryList>
        {content.map((image, i) => (
          <Image
            openOverlay={setExpanded}
            prefix={prefix}
            key={i}
            {...image}
          />
        ))}
      </GalleryList>

      {expanded && (
        <ExpandedImage closeOverlay={() => setExpanded(false)} {...expanded} />
      )}
    </Gallery>
  );
}

export default MediaGallery
