import React from "react";
import styled from "styled-components";
import { withPrefix } from "gatsby";

import { P } from "../Typography";

const Aside = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    max-height: 70vw !important;
  }

  figure {
    width: 140vmin;
    max-width: calc(100vw - 4rem);
  }
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
const LinksHeader = styled.div`
  img {
    width: 100%;
  }

  span,
  a {
    display: inline-block;
    width: 0.9rem;
    margin-left: 1rem;
    vertical-align: middle;
    cursor: pointer;
  }
  svg {
    width: 100%;
  }
`;

const ExpandedImage = ({ src, title, closeOverlay, externalSrc }) => {
  return (
    <Aside onClick={closeOverlay}>
      <figure>
        <Header>
          <P>{title}</P>

          <LinksHeader>
            <span>
              <img
                src={`${withPrefix("/images/media/collapse.svg")}`}
                alt="Collapse"
              />
            </span>
            <a href={externalSrc || src} download>
              <img
                src={`${withPrefix("/images/media/download.svg")}`}
                alt="Download"
              />
            </a>
          </LinksHeader>
        </Header>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img src={externalSrc || withPrefix(src)} alt={title} />
        </div>
      </figure>
    </Aside>
  );
};

export default ExpandedImage