import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { withPrefix } from 'gatsby'

import { H2 } from '../Typography'

const Parent = styled.div`
  .desktop-wing {
    width: 100%;
    text-align: center;
    margin: 2rem auto;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2{margin-bottom: 0}

  .mobile-wing {
    display: none;
  }

  @media (max-width: 768px) {
    margin-top: 6rem;
    margin-bottom: 1rem;
    
    .desktop-wing {
      display: none;
    }
    
    .mobile-wing {
      max-width: 40rem;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      padding: 0 1rem;
      justify-content: space-between;
      margin-top: 2rem;
    }

    h2 {
      margin: auto;
      width: 100%; padding: 0 2rem
    }
  }
`

const Wing = styled.div`
  width: 8rem;
  margin: 0 2rem;
  img {width: 100%}

  @media (max-width: 768px) {
    width: calc(100% - 1rem);

    margin: auto;
    margin-bottom: 1rem;
  }
`

export default class FeaturedTitle extends PureComponent {
  render() {
    const { children } = this.props
    return (
      <Parent>
        <div className="desktop-wing">
          <Wing><img alt='' src={withPrefix('/images/blog/title-left.png')} /></Wing>

          <H2 size='3.3rem'>{children}</H2>

          <Wing><img alt='' src={withPrefix('/images/blog/title-right.png')} /></Wing>
        </div>

        <div className="mobile-wing">
          <Wing><img alt='' src={withPrefix('/images/mobile-divide.png')} /></Wing>
          <H2 size='2.2rem'>{children}</H2>

        </div>

      </Parent>
    )
  }
}
